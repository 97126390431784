var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { PlanType } from '../../enum';
import { useAPI } from '../../state';
import { toInt } from '../../util';
import { STEPS_LENGTH, STEP_NAME } from './constants';
import { useWizardSimpleContext } from './useWizardSimpleContext';
var PLAN_API = '/api/plan/{basePlanId}/{id}';
var SCENARIO_MULTISKILL_API = '/api/multiskill/scenario/{basePlanId}';
export var MONTH_OF_YEAR = 12;
var MONTH_FORMAT = 'YY-MMM';
var LONG_MONTH_FORMAT = 'MMMM YYYY';
var WEEK_FORMAT = 'YY-MMM-DD';
export var TimelineType;
(function (TimelineType) {
    TimelineType[TimelineType["Years"] = 0] = "Years";
    TimelineType[TimelineType["Weeks"] = 1] = "Weeks";
    TimelineType[TimelineType["Dates"] = 2] = "Dates";
})(TimelineType || (TimelineType = {}));
export var selectPlan = function (plan) { return plan; };
export var selectPlanType = createSelector(selectPlan, function (plan) { var _a; return (_a = plan === null || plan === void 0 ? void 0 : plan.planType) !== null && _a !== void 0 ? _a : PlanType.Monthly; });
export var selectChunkSize = createSelector(selectPlan, function (plan) {
    return !plan || plan.planType === PlanType.Monthly ? MONTH_OF_YEAR : plan.periodLength;
});
var selectYears = createSelector(selectPlan, selectChunkSize, function (plan, chunkSize) {
    return plan && plan.periodLength !== chunkSize ? _.times(plan.periodLength / chunkSize, function (n) { return "Year ".concat(n + 1); }) : undefined;
});
export var selectWeeks = createSelector(selectPlan, function (plan) {
    return (plan === null || plan === void 0 ? void 0 : plan.planType) === PlanType.Weekly ? _.times(plan.periodLength, function (n) { return "Week ".concat(n + 1); }) : undefined;
});
export var selectPeriods = createSelector(selectPlan, function (plan) {
    return plan &&
        _.times(plan.periodLength, function (n) { return moment.utc(plan.startDate).add(n, plan.planType === PlanType.Monthly ? 'M' : 'w'); });
});
export var selectMonthly = createSelector(selectPeriods, selectPlan, function (periods, plan) {
    return (plan === null || plan === void 0 ? void 0 : plan.planType) === PlanType.Monthly ? periods === null || periods === void 0 ? void 0 : periods.map(function (m) { return m.format(MONTH_FORMAT); }) : undefined;
});
export var selectWeekDate = createSelector(selectPeriods, selectPlan, function (periods, plan) {
    return (plan === null || plan === void 0 ? void 0 : plan.planType) === PlanType.Weekly ? periods === null || periods === void 0 ? void 0 : periods.map(function (m) { return m.format(WEEK_FORMAT); }) : undefined;
});
export var selectFullWeeklyBr = createSelector(selectPeriods, selectPlan, function (periods, plan) {
    return (plan === null || plan === void 0 ? void 0 : plan.planType) === PlanType.Weekly
        ? periods === null || periods === void 0 ? void 0 : periods.map(function (m, index) { return "<b>Week ".concat(index + 1, "</b><br>").concat(m.format(WEEK_FORMAT).toUpperCase()); })
        : undefined;
});
export var selectFullWeekly = createSelector(selectPeriods, selectPlan, function (periods, plan) {
    return (plan === null || plan === void 0 ? void 0 : plan.planType) === PlanType.Weekly
        ? periods === null || periods === void 0 ? void 0 : periods.map(function (m, index) { return "Week ".concat(index + 1, " (").concat(m.format(WEEK_FORMAT), ")"); })
        : undefined;
});
var selectBusinessDays = createSelector(selectPeriods, selectPlanType, function (periods, planType) {
    return periods === null || periods === void 0 ? void 0 : periods.map(function (m) {
        return planType === PlanType.Monthly ? [m.endOf('month').businessDaysIntoMonth(), moment(m).daysInMonth()] : [5, 7];
    });
});
export var createTimelineSelector = function (includeYear, includeWeek, includeWeekDate, addColumns) {
    return createSelector(selectYears, selectWeeks, selectMonthly, selectWeekDate, selectChunkSize, function (years, weeks, monthly, weekDate, chunkSize) {
        var _a;
        return [
            includeYear ? years : undefined,
            includeWeek && weeks ? __spreadArray(__spreadArray([], weeks, true), addColumns, true) : undefined,
            (_a = (monthly &&
                _.chain(monthly)
                    .chunk(chunkSize)
                    .map(function (v) { return __spreadArray(__spreadArray([], v, true), addColumns, true); })
                    .flatten()
                    .value())) !== null && _a !== void 0 ? _a : (weekDate && includeWeekDate && __spreadArray(__spreadArray([], weekDate, true), _.times(addColumns.length, _.constant('')), true)),
        ]
            .map(function (v, i) { return v && [v, i]; })
            .filter(function (v) { return !!v; });
    });
};
var selectLongMonthly = createSelector(selectPeriods, selectPlan, function (periods, plan) {
    return (plan === null || plan === void 0 ? void 0 : plan.planType) === PlanType.Monthly ? periods === null || periods === void 0 ? void 0 : periods.map(function (m) { return m.format(LONG_MONTH_FORMAT); }) : undefined;
});
export var selectTimelineOptions = createSelector(selectLongMonthly, selectWeekDate, selectWeeks, function (monthly, weekly, weeks) {
    var _a;
    return ((_a = monthly !== null && monthly !== void 0 ? monthly : weekly) !== null && _a !== void 0 ? _a : []).map(function (name, id) { return ({
        id: id,
        name: weeks ? "".concat(weeks[id], " (").concat(name, ")") : name,
    }); });
});
export var usePlanAPI = function () {
    var _a = useParams(), basePlanId = _a.basePlanId, id = _a.id;
    return useAPI(PLAN_API, { basePlanId: basePlanId, id: id });
};
export var usePlanType = function () {
    var _a;
    var plan = ((_a = useWizardSimpleContext()) !== null && _a !== void 0 ? _a : {}).plan;
    return selectPlanType(plan);
};
export var useDetailTimeline = function () {
    var _a;
    var addColumns = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        addColumns[_i] = arguments[_i];
    }
    var plan = ((_a = useWizardSimpleContext()) !== null && _a !== void 0 ? _a : {}).plan;
    var selectTimelines = useMemo(function () { return createTimelineSelector(false, true, true, addColumns); }, [addColumns]);
    return selectTimelines(plan);
};
export var useBusinessDays = function () {
    var data = usePlanAPI()[0].data;
    return selectBusinessDays(data);
};
export var useWizardParams = function () {
    var _a = useParams(), basePlanId = _a.basePlanId, id = _a.id;
    var searchParams = useSearchParams()[0];
    return useMemo(function () {
        var step = Math.max(Math.min(toInt(searchParams.get('step'), 0), STEPS_LENGTH - 1), 0);
        return {
            basePlanId: toInt(basePlanId),
            id: toInt(id),
            queueId: toInt(searchParams.get('queueId')),
            step: step,
            stepName: STEP_NAME[step],
        };
    }, [basePlanId, id, searchParams]);
};
export var usePeriodChunkSize = function () {
    var _a;
    var plan = ((_a = useWizardSimpleContext()) !== null && _a !== void 0 ? _a : {}).plan;
    return selectChunkSize(plan);
};
export var useDeleteAllMultiskillAPI = function () {
    var basePlanId = useParams().basePlanId;
    return useAPI(SCENARIO_MULTISKILL_API, { basePlanId: basePlanId }, 'DELETE');
};
