var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link, Stack, styled } from '@mui/material';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CURRENCY_CODE, Currency, PlanType } from '../../enum';
import { MULTISKILL_COLOR } from '../annual/constants';
import { usePlatformContext } from '../app/selectors';
import { ErrorMessageBox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../common';
import { ADJUSTED_COLOR, FLAG } from '../daily/constants';
import { useReportTimeline } from '../daily/selectors';
import { QueueType } from '../multiskill/types';
import { useExportAPI, useReportAPI } from '../report/selectors';
import { PLAN_COLORS } from '../wizard/constants';
import { TimelineType } from '../wizard/selectors';
import { DOUBLE_HYPHEN } from '../wizard/workArrivalPattern/selectors';
import { COST_ROWS, CURRENCY, HIDE_ROW, ONE_DECIMAL_VALUE, ONLY_PLAN_DATA, ONLY_SINGLE_QUEUE, PERCENTAGES, PERIOD_REPORT_ROWS, PERSON_FACTOR, STAFF_BUDGET_ROWS, STAFF_REQUIREMENT_ROWS, TOTAL_DATA, metricTargetMap, } from './constants';
import { useGetTotalCol, usePeriodReportAPI, usePeriodReportByMode, usePeriodReportChunkSize, usePrintPeriodTableAPI, } from './selectors';
var STICKIES = ['10.75rem'];
var DEFAULT_ADD_COLUMNS = ['Average'];
export var StyledTableCell = styled(TableCell)(function (_a) {
    var theme = _a.theme;
    return ({
        minWidth: theme.typography.pxToRem(100),
    });
});
export var StyledFirstCell = styled(TableCell)(function (_a) {
    var theme = _a.theme, paddingLeft = _a["aria-label"];
    return ({
        textAlign: 'left',
        minWidth: theme.typography.pxToRem(400),
        '&.MuiTableCell-root': {
            paddingLeft: paddingLeft,
            borderLeft: "1px solid ".concat(theme.palette.grey[600]),
        },
    });
});
export var PeriodTableHead = function (_a) {
    var _b;
    var _c = _a.headColor, headColor = _c === void 0 ? ADJUSTED_COLOR : _c, _d = _a.addColumns, addColumns = _d === void 0 ? DEFAULT_ADD_COLUMNS : _d, dataType = _a.dataType, props = __rest(_a, ["headColor", "addColumns", "dataType"]);
    var t = useTranslation('report').t;
    var reportData = useReportAPI()[0].data;
    var timelines = useReportTimeline.apply(void 0, __spreadArray([!props.index, !props.index, !props.index], addColumns, false));
    var planType = (reportData !== null && reportData !== void 0 ? reportData : {}).planType;
    var isIncludeTotal = addColumns.includes('Total');
    var totalCol = (_b = useGetTotalCol()) !== null && _b !== void 0 ? _b : 1;
    var displayName = props.namePlan + (dataType === QueueType.Multiskill ? " ".concat(t('multiskill')) : '');
    var getColSpan = function (title, type) {
        if (type === TimelineType.Years) {
            return 14;
        }
        return ['Average', ''].includes(title) && !isIncludeTotal ? 2 : 1;
    };
    var getValue = function (title, type, index, arr) {
        if (type === TimelineType.Weeks && ['Average', 'Total'].includes(title)) {
            return '';
        }
        if (index === arr - 1) {
            return 'Average';
        }
        if (index === arr - 2 && isIncludeTotal) {
            return 'Total';
        }
        return title;
    };
    return (_jsx(TableHead, { children: timelines.map(function (_a, rowIdx) {
            var titles = _a[0], type = _a[1];
            return (_jsxs(React.Fragment, { children: [!rowIdx && props.title && planType === PlanType.Monthly && (_jsxs(TableRow, { children: [_jsx(StyledFirstCell, { stickySizes: STICKIES, value: props.title }), _jsx(TableCell, { colSpan: totalCol - 1 })] })), _jsxs(TableRow, { children: [!rowIdx ? (_jsx(_Fragment, { children: type === TimelineType.Weeks ? (_jsx(StyledFirstCell, { stickySizes: STICKIES, value: props.title })) : (_jsx(StyledFirstCell, { rowSpan: timelines.length, color: headColor, stickySizes: STICKIES, value: displayName })) })) : (planType === PlanType.Weekly && (_jsx(StyledFirstCell, { color: headColor, stickySizes: STICKIES, value: displayName }))), titles === null || titles === void 0 ? void 0 : titles.map(function (title, cellIdx, arr) { return (_jsx(StyledTableCell, { color: type !== TimelineType.Weeks ? headColor : undefined, colSpan: getColSpan(title, type), highlightBorder: cellIdx !== arr.length - 1 && (type === TimelineType.Years || ['Average'].includes(title)), value: planType === PlanType.Weekly ? getValue(title, type, cellIdx, arr.length) : title }, cellIdx)); })] })] }, type));
        }) }));
};
var MainRow = function (_a) {
    var title = _a.title, rowColor = _a.rowColor, summaryColor = _a.summaryColor, _b = _a.isHideRow, isHideRow = _b === void 0 ? false : _b, _c = _a.averagePrecision, averagePrecision = _c === void 0 ? 0 : _c, data = _a.data, _d = _a.isIncludeTotal, isIncludeTotal = _d === void 0 ? false : _d, isCurrency = _a.isCurrency, _e = _a.isMultiskill, isMultiskill = _e === void 0 ? false : _e, props = __rest(_a, ["title", "rowColor", "summaryColor", "isHideRow", "averagePrecision", "data", "isIncludeTotal", "isCurrency", "isMultiskill"]);
    var t = useTranslation('report', { keyPrefix: 'period' }).t;
    var reportData = useReportAPI()[0].data;
    var chunkSize = usePeriodReportChunkSize();
    var _f = reportData !== null && reportData !== void 0 ? reportData : {}, _g = _f.periodLength, periodLength = _g === void 0 ? 0 : _g, _h = _f.currency, currency = _h === void 0 ? Currency.Dollar : _h;
    var calculateTotalAvgForStaff = function (dataPlan) {
        return _.chain(periodLength)
            .times()
            .map(function (i) { var _a; return Number((_a = dataPlan === null || dataPlan === void 0 ? void 0 : dataPlan[i]) !== null && _a !== void 0 ? _a : 0); })
            .chunk(chunkSize)
            .map(function (arr) { return ({
            total: _.chain(arr).sum().round(averagePrecision).value(),
            avg: _.chain(arr).mean().value(),
        }); })
            .map(function (_a) {
            var avg = _a.avg, rest = __rest(_a, ["avg"]);
            return (__assign({ avg: props.isPersonFactor ? _.ceil(avg, averagePrecision) : _.round(avg, averagePrecision) }, rest));
        })
            .value();
    };
    var summary = calculateTotalAvgForStaff(data);
    var getAvg = function (index) {
        if (props.calculated) {
            return props.calculated[index];
        }
        return data ? summary[index].avg : undefined;
    };
    return (_jsxs(TableRow, { children: [_jsx(StyledFirstCell, __assign({ color: rowColor, bold: props.bold, stickySizes: STICKIES, "aria-label": props.bold ? '1.25rem' : undefined }, { children: _jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between" }, { children: [title, isHideRow && (_jsx(Link, __assign({ component: "button", onClick: props.onShowRow, underline: "none", fontWeight: "normal" }, { children: props.showDetails ? "- ".concat(t('hideDetails')) : "+ ".concat(t('showDetails')) })))] })) })), summary.map(function (item, year, arr) { return (_jsxs(React.Fragment, { children: [(data ? data.slice(chunkSize * year, chunkSize * year + chunkSize) : Array(chunkSize).fill(null)).map(function (value, i) { return (_jsx(StyledTableCell, { color: isMultiskill ? MULTISKILL_COLOR : rowColor, value: value, percentage: props.percentage, currency: isCurrency ? CURRENCY_CODE[currency] : undefined, bold: props.bold, decimal: averagePrecision }, i)); }), isIncludeTotal && (_jsx(StyledTableCell, { color: isMultiskill ? MULTISKILL_COLOR : summaryColor !== null && summaryColor !== void 0 ? summaryColor : rowColor, decimal: averagePrecision, currency: isCurrency ? CURRENCY_CODE[currency] : undefined, value: data ? item.total : undefined, percentage: props.percentage, bold: props.bold })), _jsx(StyledTableCell, { color: isMultiskill ? MULTISKILL_COLOR : summaryColor !== null && summaryColor !== void 0 ? summaryColor : rowColor, decimal: averagePrecision, colSpan: isIncludeTotal ? 1 : 2, highlightBorder: year !== arr.length - 1, percentage: props.percentage, currency: isCurrency ? CURRENCY_CODE[currency] : undefined, value: getAvg(year), bold: props.bold })] }, year)); })] }));
};
var QueueRow = function (_a) {
    var data = _a.data, _b = _a.averagePrecision, averagePrecision = _b === void 0 ? 0 : _b, _c = _a.isIncludeTotal, isIncludeTotal = _c === void 0 ? false : _c, keyItem = _a.keyItem, rowColor = _a.rowColor, role = _a.role, percentage = _a.percentage, isCurrency = _a.isCurrency, _d = _a.isPersonFactor, isPersonFactor = _d === void 0 ? false : _d, _e = _a.isCalculated, isCalculated = _e === void 0 ? false : _e, _f = _a.isMultiskill, isMultiskill = _f === void 0 ? false : _f;
    var reportData = useReportAPI()[0].data;
    var chunkSize = usePeriodReportChunkSize();
    var _g = reportData !== null && reportData !== void 0 ? reportData : {}, _h = _g.periodLength, periodLength = _h === void 0 ? 0 : _h, _j = _g.currency, currency = _j === void 0 ? Currency.Dollar : _j;
    var calculateTotalAvgForStaff = function (dataPlan) {
        return dataPlan.map(function (queue) {
            return _.chain(periodLength)
                .times()
                .map(function (i) { var _a, _b; return Number((_b = (_a = (role ? _.get(queue, "".concat(keyItem, ".").concat(role)) : _.get(queue, keyItem))) === null || _a === void 0 ? void 0 : _a[i]) !== null && _b !== void 0 ? _b : 0); })
                .chunk(chunkSize)
                .map(function (arr) { return ({
                total: _.chain(arr).sum().round(averagePrecision).value(),
                avg: _.chain(arr).mean().value(),
            }); })
                .map(function (_a) {
                var avg = _a.avg, rest = __rest(_a, ["avg"]);
                return (__assign({ avg: isPersonFactor ? _.ceil(avg, averagePrecision) : avg }, rest));
            })
                .value();
        });
    };
    var summary = calculateTotalAvgForStaff(data);
    var getAvg = function (calculatedValue, value) {
        if (isCalculated) {
            return calculatedValue;
        }
        return isNaN(value) ? DOUBLE_HYPHEN : value;
    };
    return (_jsx(_Fragment, { children: data.map(function (queue, index) {
            return !isMultiskill && queue.dataType === QueueType.Multiskill ? null : (_jsxs(TableRow, { children: [_jsxs(StyledFirstCell, __assign({ stickySizes: STICKIES, align: "left", "aria-label": "2.5rem", bold: queue.dataType === QueueType.Multiskill, color: queue.dataType === QueueType.Multiskill ? MULTISKILL_COLOR : undefined }, { children: [queue.displayName, " ", data[index].metricTarget === metricTargetMap[keyItem] && FLAG] })), summary[index].map(function (item, year, arr) {
                        var _a, _b;
                        return (_jsxs(React.Fragment, { children: [(_a = (role ? _.get(queue, "".concat(keyItem, ".").concat(role)) : queue[keyItem])) === null || _a === void 0 ? void 0 : _a.slice(chunkSize * year, chunkSize * year + chunkSize).map(function (value, i) { return (_jsx(StyledTableCell, { percentage: percentage, currency: isCurrency ? CURRENCY_CODE[currency] : undefined, value: value, decimal: averagePrecision, color: isMultiskill && (queue.dataType === QueueType.Multiskill || queue.group !== null)
                                        ? MULTISKILL_COLOR
                                        : undefined }, i)); }), isIncludeTotal && (_jsx(StyledTableCell, { color: isMultiskill && (queue.dataType === QueueType.Multiskill || queue.group !== null)
                                        ? MULTISKILL_COLOR
                                        : rowColor, decimal: averagePrecision, currency: isCurrency ? CURRENCY_CODE[currency] : undefined, percentage: percentage, value: isNaN(item.total) ? DOUBLE_HYPHEN : item.total })), _jsx(StyledTableCell, { color: isMultiskill && (queue.dataType === QueueType.Multiskill || queue.group !== null)
                                        ? MULTISKILL_COLOR
                                        : rowColor, decimal: averagePrecision, colSpan: isIncludeTotal ? 1 : 2, highlightBorder: year !== arr.length - 1, percentage: percentage, currency: isCurrency ? CURRENCY_CODE[currency] : undefined, value: getAvg((_b = queue.summary[keyItem]) === null || _b === void 0 ? void 0 : _b[year], item.avg) })] }, year));
                    })] }, queue.displayName));
        }) }));
};
var PeriodTableBody = function (_a) {
    var data = _a.data, _b = _a.addColumns, addColumns = _b === void 0 ? DEFAULT_ADD_COLUMNS : _b, _c = _a.rowItem, rowItem = _c === void 0 ? STAFF_REQUIREMENT_ROWS : _c, _d = _a.extendItem, extendItem = _d === void 0 ? 'supportStaff' : _d, _e = _a.adjustItem, adjustItem = _e === void 0 ? 'totalSupportStaff' : _e;
    var _f = useState([]), showDetails = _f[0], setShowDetails = _f[1];
    var onShowRow = function (item) {
        setShowDetails(function (prev) { return (prev.includes(item) ? prev.filter(function (i) { return i !== item; }) : __spreadArray(__spreadArray([], prev, true), [item], false)); });
    };
    var getAveragePrecision = function (item) {
        if (ONE_DECIMAL_VALUE.includes(item)) {
            return 1;
        }
        return COST_ROWS.includes(item) ? 2 : 0;
    };
    return (_jsx(TableBody, { children: rowItem
            .filter(function (item) { return !TOTAL_DATA.includes(item); })
            .map(function (item) {
            var _a, _b, _c;
            var isMultiskill = data.dataType === QueueType.Multiskill && !ONLY_SINGLE_QUEUE.includes(item);
            return (_jsxs(React.Fragment, { children: [!COST_ROWS.includes(item) && (_jsx(MainRow, { bold: true, data: item === extendItem ? data[adjustItem] : data[item], rowColor: (_a = PLAN_COLORS[data === null || data === void 0 ? void 0 : data.colorIndex]) === null || _a === void 0 ? void 0 : _a[1], title: PERIOD_REPORT_ROWS[item], isHideRow: HIDE_ROW.includes(item), isIncludeTotal: addColumns.includes('Total'), averagePrecision: getAveragePrecision(item), percentage: PERCENTAGES.includes(item), isCurrency: CURRENCY.includes(item), onShowRow: function () { return onShowRow(item); }, showDetails: showDetails.includes(item), calculated: item === 'requiredAgents' ? (_b = data.summary) === null || _b === void 0 ? void 0 : _b[item] : undefined, isPersonFactor: PERSON_FACTOR.includes(item), isMultiskill: isMultiskill })), (showDetails.includes(item) || !HIDE_ROW.includes(item)) && (_jsx(_Fragment, { children: item === extendItem
                            ? Object.keys(data[item]).map(function (role) {
                                var _a, _b;
                                return (_jsxs(React.Fragment, { children: [_jsx(MainRow, { bold: true, data: data[item][role], title: role, summaryColor: (_a = PLAN_COLORS[data === null || data === void 0 ? void 0 : data.colorIndex]) === null || _a === void 0 ? void 0 : _a[1], averagePrecision: getAveragePrecision(item), isCurrency: CURRENCY.includes(item), isIncludeTotal: addColumns.includes('Total'), isPersonFactor: PERSON_FACTOR.includes(item), isMultiskill: isMultiskill }), _jsx(QueueRow, { data: data.queues, isIncludeTotal: addColumns.includes('Total'), keyItem: item, rowColor: (_b = PLAN_COLORS[data === null || data === void 0 ? void 0 : data.colorIndex]) === null || _b === void 0 ? void 0 : _b[1], role: role, averagePrecision: getAveragePrecision(item), isCurrency: CURRENCY.includes(item), isPersonFactor: PERSON_FACTOR.includes(item), isMultiskill: isMultiskill })] }, role));
                            })
                            : !ONLY_PLAN_DATA.includes(item) && (_jsx(QueueRow, { data: data.queues, isIncludeTotal: addColumns.includes('Total'), keyItem: item, rowColor: (_c = PLAN_COLORS[data === null || data === void 0 ? void 0 : data.colorIndex]) === null || _c === void 0 ? void 0 : _c[1], averagePrecision: getAveragePrecision(item), percentage: PERCENTAGES.includes(item), isCurrency: CURRENCY.includes(item), isCalculated: item === 'requiredAgents', isPersonFactor: PERSON_FACTOR.includes(item), isMultiskill: isMultiskill })) }))] }, item));
        }) }));
};
var PeriodTable = function (props) {
    var periodData = usePeriodReportAPI()[0];
    if (!periodData.data)
        return null;
    return (_jsx(TableContainer, __assign({ topScroll: true, sx: { border: 'unset' } }, { children: _jsx(Table, { children: props.children }) })));
};
export var ResourcingTable = function () {
    var t = useTranslation('report', { keyPrefix: 'period' }).t;
    var periodData = usePeriodReportAPI()[0];
    var printData = usePrintPeriodTableAPI()[0];
    var exportData = useExportAPI()[0];
    var periodDataMode = usePeriodReportByMode();
    return (_jsxs(_Fragment, { children: [_jsx(ErrorMessageBox, { errors: [periodData.error, printData.error, exportData.error] }), _jsx(PeriodTable, { children: periodDataMode === null || periodDataMode === void 0 ? void 0 : periodDataMode.map(function (plan, index) {
                    var _a;
                    return (_jsxs(React.Fragment, { children: [_jsx(PeriodTableHead, { title: !index ? t('staffRequirements') : undefined, index: index, namePlan: plan.displayName, headColor: (_a = PLAN_COLORS[plan === null || plan === void 0 ? void 0 : plan.colorIndex]) === null || _a === void 0 ? void 0 : _a[0], dataType: plan.dataType }), _jsx(PeriodTableBody, { data: plan, rowItem: STAFF_REQUIREMENT_ROWS })] }, "".concat(plan.displayName, "-").concat(plan.dataType)));
                }) })] }));
};
export var FinancialTable = function () {
    var t = useTranslation('report', { keyPrefix: 'period' }).t;
    var periodData = usePeriodReportAPI()[0];
    var printData = usePrintPeriodTableAPI()[0];
    var exportData = useExportAPI()[0];
    var totalCol = useGetTotalCol();
    var context = usePlatformContext();
    var periodDataMode = usePeriodReportByMode();
    return (_jsxs(_Fragment, { children: [_jsx(ErrorMessageBox, { errors: [periodData.error, printData.error, exportData.error] }), _jsxs(PeriodTable, { children: [periodDataMode === null || periodDataMode === void 0 ? void 0 : periodDataMode.map(function (plan, index) {
                        var _a;
                        return (_jsxs(React.Fragment, { children: [_jsx(PeriodTableHead, { title: !index ? t('staffBudget') : undefined, index: index, dataType: plan.dataType, namePlan: plan.displayName, headColor: (_a = PLAN_COLORS[plan === null || plan === void 0 ? void 0 : plan.colorIndex]) === null || _a === void 0 ? void 0 : _a[0], addColumns: ['Total', 'Average'] }), _jsx(PeriodTableBody, { data: plan, addColumns: ['Total', 'Average'], rowItem: STAFF_BUDGET_ROWS, extendItem: "supportStaffSalaries", adjustItem: "totalSupportStaffSalaries" })] }, "".concat(plan.displayName, "-").concat(plan.dataType)));
                    }), _jsx(TableBody, { children: _jsx(TableRow, { children: _jsx(TableCell, { colSpan: totalCol, sx: { borderRight: 'none' }, color: "#F2F2F2" }) }) }), periodDataMode === null || periodDataMode === void 0 ? void 0 : periodDataMode.map(function (plan, index) {
                        var _a;
                        return (_jsxs(React.Fragment, { children: [_jsx(PeriodTableHead, { title: !index ? t('costPerWork', { context: context }) : undefined, index: index, namePlan: plan.displayName, headColor: (_a = PLAN_COLORS[plan === null || plan === void 0 ? void 0 : plan.colorIndex]) === null || _a === void 0 ? void 0 : _a[0], dataType: plan.dataType }), _jsx(PeriodTableBody, { data: plan, rowItem: COST_ROWS })] }, "".concat(plan.displayName, "-").concat(plan.dataType)));
                    })] })] }));
};
