var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, TextField, Typography, } from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { dateToString } from '../../util';
import { Autocomplete, DatePicker, ErrorMessageBox, FallbackComponent, PhoneInput } from '../common';
import { FormField } from '../common/formField';
import { useAddCompanyAPI, useCompaniesAPI, useCompanyAPI, useCountryAPI, useIndustryAPI, useUpdateCompanyAPI, useUsersAPI, } from './selectors';
import { LicenseType } from './types';
var validateForm = yup.object().shape({
    name: yup.string().required().trim(),
    countryId: yup.number().required(),
    industryId: yup.number().required(),
    capacityLicense: yup.number().required(),
    financialLicense: yup.number().required(),
    recruitmentLicense: yup.number().required(),
    subscriptionStartDate: yup.object().nullable().required(),
    subscriptionEndDate: yup.object().nullable().required(),
    subscriptionEndDateError: yup.string().optional().max(0, 'Invalid'),
    subscriptionStartDateError: yup.string().optional().max(0, 'Invalid'),
    contactName: yup.string().required().max(50).trim().label('Name'),
    contactPhone: yup.string().max(20).phone().trim().label('Phone'),
    contactEmail: yup.string().email().required().max(50).trim().label('Email'),
});
export var CompanyDialog = function (_a) {
    var _b;
    var user = _a.user, open = _a.open, onClose = _a.onClose;
    var t = useTranslation(['manageUser', 'translation']).t;
    var orgId = (_b = user === null || user === void 0 ? void 0 : user.orgs[0]) === null || _b === void 0 ? void 0 : _b.id;
    var _c = useCountryAPI(), countries = _c[0], fetchCountries = _c[1], clearCountries = _c[2];
    var _d = useIndustryAPI(), industry = _d[0], fetchIndustry = _d[1], clearIndustry = _d[2];
    var _e = useCompanyAPI(orgId), orgData = _e[0], fetchOrgData = _e[1], clearOrgData = _e[2];
    var _f = useAddCompanyAPI(), addCompanyData = _f[0], addCompany = _f[1], clearAddCompanyData = _f[2];
    var _g = useUpdateCompanyAPI(orgId), updateCompanyData = _g[0], updateCompany = _g[1], clearUpdateCompanyData = _g[2];
    var _h = useCompaniesAPI(), fetchOrgs = _h[1];
    var _j = useUsersAPI(), fetchUsers = _j[1];
    var defaultValues = useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
        return ({
            name: (_b = (_a = orgData.data) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '',
            countryId: (_d = (_c = orgData.data) === null || _c === void 0 ? void 0 : _c.countryId) !== null && _d !== void 0 ? _d : (_f = (_e = countries.data) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.id,
            industryId: (_h = (_g = orgData.data) === null || _g === void 0 ? void 0 : _g.industryId) !== null && _h !== void 0 ? _h : (_k = (_j = industry.data) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.id,
            contactPhone: (_m = (_l = orgData.data) === null || _l === void 0 ? void 0 : _l.contactPhone) !== null && _m !== void 0 ? _m : '',
            contactName: (_p = (_o = orgData.data) === null || _o === void 0 ? void 0 : _o.contactName) !== null && _p !== void 0 ? _p : '',
            contactEmail: (_r = (_q = orgData.data) === null || _q === void 0 ? void 0 : _q.contactEmail) !== null && _r !== void 0 ? _r : '',
            subscriptionStartDate: ((_s = orgData.data) === null || _s === void 0 ? void 0 : _s.subscriptionStartDate)
                ? moment.utc(orgData.data.subscriptionStartDate)
                : moment().utc().startOf('y'),
            subscriptionEndDate: ((_t = orgData.data) === null || _t === void 0 ? void 0 : _t.subscriptionEndDate)
                ? moment.utc((_u = orgData.data) === null || _u === void 0 ? void 0 : _u.subscriptionEndDate).endOf('D')
                : moment().utc().endOf('y'),
            capacityLicense: LicenseType.Yes,
            recruitmentLicense: (_w = (_v = orgData.data) === null || _v === void 0 ? void 0 : _v.recruitmentLicense) !== null && _w !== void 0 ? _w : LicenseType.No,
            financialLicense: (_y = (_x = orgData.data) === null || _x === void 0 ? void 0 : _x.financialLicense) !== null && _y !== void 0 ? _y : LicenseType.No,
        });
    }, [countries.data, industry.data, orgData.data]);
    var _k = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(validateForm),
    }), control = _k.control, handleSubmit = _k.handleSubmit, reset = _k.reset, watch = _k.watch, setValue = _k.setValue;
    var _l = watch(), subscriptionStartDate = _l.subscriptionStartDate, recruitmentLicense = _l.recruitmentLicense, capacityLicense = _l.capacityLicense, financialLicense = _l.financialLicense;
    useEffect(function () {
        if (open) {
            orgId && fetchOrgData();
            fetchCountries();
            fetchIndustry();
            return function () {
                clearOrgData();
                clearCountries();
                clearIndustry();
                clearAddCompanyData();
                clearUpdateCompanyData();
            };
        }
    }, [
        clearAddCompanyData,
        clearCountries,
        clearIndustry,
        clearOrgData,
        clearUpdateCompanyData,
        fetchCountries,
        fetchIndustry,
        fetchOrgData,
        open,
        orgId,
    ]);
    useEffect(function () {
        if (open) {
            reset(defaultValues);
        }
    }, [defaultValues, open, reset]);
    useEffect(function () {
        if (addCompanyData.data || updateCompanyData.data) {
            onClose();
            fetchOrgs();
            fetchUsers();
        }
    }, [addCompanyData.data, fetchOrgs, onClose, fetchUsers, updateCompanyData.data]);
    var handleFormSubmit = function (_a) {
        var startDate = _a.subscriptionStartDate, endDate = _a.subscriptionEndDate, values = __rest(_a, ["subscriptionStartDate", "subscriptionEndDate"]);
        var payload = __assign(__assign({}, values), { subscriptionStartDate: dateToString(startDate), subscriptionEndDate: dateToString(endDate) });
        if (orgId) {
            updateCompany(__assign({}, payload));
        }
        else {
            addCompany(payload);
        }
    };
    return (_jsx(Dialog, __assign({ open: open, onClose: onClose }, { children: _jsxs(Box, __assign({ component: "form", onSubmit: handleSubmit(handleFormSubmit) }, { children: [_jsx(DialogTitle, __assign({ variant: "h2" }, { children: t(orgId ? 'editCompanyTittle' : 'addNewCompanyTittle') })), _jsx(DialogContent, { children: _jsxs(FallbackComponent, __assign({ requesting: addCompanyData.requesting, overlay: true }, { children: [_jsx(ErrorMessageBox, { errors: [addCompanyData.error] }), _jsxs(Grid, __assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(FormField, __assign({ required: true, name: "name", label: t('company'), control: control }, { children: _jsx(TextField, { inputProps: {
                                                    maxLength: 50,
                                                } }) })) })), _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(FormField, __assign({ required: true, name: "countryId", label: t('country'), control: control }, { children: _jsx(Autocomplete, { options: countries.data, loading: countries.requesting }) })) })), _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(FormField, __assign({ required: true, name: "subscriptionStartDate", label: t('subscriptionStartDate'), control: control }, { children: _jsx(DatePicker, { disableFuture: true, control: control, disabled: false }) })) })), _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(FormField, __assign({ required: true, name: "subscriptionEndDate", label: t('subscriptionEndDate'), control: control }, { children: _jsx(DatePicker, { minDate: subscriptionStartDate, control: control, disabled: false }) })) })), _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(FormField, __assign({ required: true, name: "contactName", label: t('contactName'), control: control }, { children: _jsx(TextField, { inputProps: {
                                                    maxLength: 50,
                                                } }) })) })), _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(FormField, __assign({ required: true, name: "contactEmail", label: t('contactEmail'), control: control }, { children: _jsx(TextField, { type: "email", inputProps: {
                                                    maxLength: 50,
                                                } }) })) })), _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(FormField, __assign({ name: "contactPhone", label: t('contactPhone'), control: control }, { children: _jsx(PhoneInput, { "data-testid": "phoneInput" }) })) })), _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(FormField, __assign({ required: true, name: "industryId", label: t('industry'), control: control }, { children: _jsx(Autocomplete, { options: industry.data, loading: industry.requesting }) })) })), _jsxs(Grid, __assign({ item: true, xs: 12, display: "flex", flexDirection: "column" }, { children: [_jsxs(Typography, __assign({ variant: "subtitle2" }, { children: [" ", t('licenseInfo')] })), _jsx(FormControlLabel, { control: _jsx(Checkbox, { size: "small", disabled: true, checked: !!capacityLicense, onChange: function (e, checked) {
                                                        return setValue('capacityLicense', checked ? LicenseType.Yes : LicenseType.No, {
                                                            shouldValidate: true,
                                                        });
                                                    } }), label: t('capacityPlanning') }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { size: "small", checked: !!financialLicense, onChange: function (e, checked) {
                                                        return setValue('financialLicense', checked ? LicenseType.Yes : LicenseType.No, {
                                                            shouldValidate: true,
                                                        });
                                                    } }), label: t('financials') }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { size: "small", checked: !!recruitmentLicense, onChange: function (e, checked) {
                                                        return setValue('recruitmentLicense', checked ? LicenseType.Yes : LicenseType.No, {
                                                            shouldValidate: true,
                                                        });
                                                    } }), label: t('recruitmentAndTrainingPlanner') })] }))] }))] })) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ variant: "outlined", onClick: onClose }, { children: t('translation:action.cancel') })), _jsx(LoadingButton, __assign({ variant: "contained", type: "submit", loading: addCompanyData.requesting }, { children: t('translation:action.save') }))] })] })) })));
};
