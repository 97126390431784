var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormHelperText, InputLabel as MuiInputLabel, Stack, Typography, formLabelClasses, styled, } from '@mui/material';
import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useFormlessContext } from '../../hooks/useFormlessContext';
export var InputLabel = styled(MuiInputLabel)(function (_a) {
    var _b;
    var theme = _a.theme, hidden = _a.hidden;
    return (_b = {
            color: theme.palette.text.primary,
            marginBottom: theme.spacing(1.2),
            fontWeight: theme.typography.subtitle2.fontWeight,
            fontSize: theme.typography.subtitle2.fontSize,
            visibility: hidden ? 'hidden' : 'visible',
            whiteSpace: 'normal'
        },
        _b[".".concat(formLabelClasses.asterisk)] = {
            color: 'red',
        },
        _b);
});
export var UnControlledFormField = function (props) {
    var label = props.label, required = props.required, disabled = props.disabled, extraLabel = props.extraLabel, children = props.children, unitLabel = props.unitLabel, helperText = props.helperText, boxProps = __rest(props, ["label", "required", "disabled", "extraLabel", "children", "unitLabel", "helperText"]);
    return (_jsxs(Box, __assign({}, boxProps, { children: [_jsxs(Stack, __assign({ direction: "row", alignItems: "center", spacing: 2, marginBottom: function (theme) { return (label ? theme.spacing(1.2) : 0); } }, { children: [label && (_jsx(InputLabel, __assign({ required: required, disabled: disabled }, { children: label }))), extraLabel && _jsx(Typography, { children: extraLabel })] })), _jsxs(Stack, __assign({ direction: "row", alignItems: "center", spacing: 2 }, { children: [children, unitLabel && _jsx(Typography, __assign({ variant: "body1" }, { children: unitLabel }))] })), helperText && _jsx(FormHelperText, __assign({ error: true }, { children: helperText }))] })));
};
export var FormField = function (props) {
    var t = useTranslation('translation', { keyPrefix: 'form' }).t;
    var methods = useFormlessContext();
    var name = props.name, _a = props.control, control = _a === void 0 ? methods === null || methods === void 0 ? void 0 : methods.control : _a, defaultValue = props.defaultValue, disableHelperText = props.disableHelperText, children = props.children, boxProps = __rest(props, ["name", "control", "defaultValue", "disableHelperText", "children"]);
    var _b = useController({ name: name, control: control, defaultValue: defaultValue }), _c = _b.field, inputRef = _c.ref, restField = __rest(_c, ["ref"]), _d = _b.fieldState, error = _d.invalid, _e = _d.error, _f = _e === void 0 ? {} : _e, message = _f.message;
    var helperText = message && t(message, { defaultValue: message });
    return (_jsx(UnControlledFormField, __assign({}, boxProps, { helperText: !disableHelperText ? helperText : undefined }, { children: React.Children.map(children, function (child) {
            return React.cloneElement(child, __assign(__assign({}, restField), { inputRef: inputRef, error: error, onChange: function () {
                    var _a, _b;
                    var event = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        event[_i] = arguments[_i];
                    }
                    if (((_b = (_a = child.props).onChange) === null || _b === void 0 ? void 0 : _b.call.apply(_b, __spreadArray([_a], event, false))) === false) {
                        return;
                    }
                    restField.onChange.apply(restField, event);
                } }));
        }) })));
};
