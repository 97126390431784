var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var TRAINING_KEY_EDITABLE = [
    'adjustedAttritionRate',
    'forecastedGraduationRate',
    'trainingLength',
    'classSize',
    'recruitingLeadTime',
];
export var NON_DEFERRABLE_BOTTOM_KEY = ['forecastedServiceLevel', 'forecastedAnswerSpeed', 'forecastedOccupancy'];
export var TRAINING_KEY_BOTTOM = __spreadArray(['staffOverUnder'], NON_DEFERRABLE_BOTTOM_KEY, true);
export var TRAINING_KEY = __spreadArray(__spreadArray(__spreadArray([
    'forecastAttritionRate',
    'adjustedAttritionRate',
    'rosteredStaffPlanned',
    'forecastedAgentAttrition'
], TRAINING_KEY_EDITABLE.filter(function (key) { return key !== 'adjustedAttritionRate'; }), true), [
    'totalLeadTimeToGraduation',
    'totalNewHireRequired',
    'recruitingStartDate',
    'newHireClassStartDate',
    'trainerRequire'
], false), TRAINING_KEY_BOTTOM, true);
export var SUMMARY_TRAINING_KEY = [
    'totalNewHireRequired',
    'recruitingStartDate',
    'newHireClassStartDate',
    'trainerRequire',
];
export var SUMMARY_TRAINING_KEY_BOTTOM = ['totalNewHireRequired', 'staffOverUnder', 'trainerRequire'];
export var PERCENTAGE_KEYS = [
    'forecastAttritionRate',
    'adjustedAttritionRate',
    'forecastedGraduationRate',
    'forecastedServiceLevel',
    'forecastedOccupancy',
];
export var BOLD_KEYS = __spreadArray(__spreadArray(__spreadArray([], SUMMARY_TRAINING_KEY, true), TRAINING_KEY_BOTTOM, true), ['forecastAttritionRate'], false);
