var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OrgType } from '../../enum';
import { useAPI } from '../../state';
import { useAsyncDownload } from '../hooks';
var USERS_API = '/api/user';
var GET_COUNTRY_API = '/api/country';
var GET_INDUSTRY_API = '/api/industry';
var USER_API = '/api/user/{id}/{orgType}';
var ORG_API = '/api/org';
var RESET_PASSWORD_API = '/api/user/resetPassword/{id}';
var CHANGE_STATUS_API = '/api/user/changeStatus';
var CREATE_EXPORT_LOG_API = '/api/report/audit/export';
var CREATE_BILLING_LOG_API = '/api/report/billing/export';
var selectCompanies = function (orgs) { return orgs; };
var selectCompanyCountries = createSelector(selectCompanies, function (orgs) {
    return _.chain(orgs)
        .filter('country')
        .map(function (_a) {
        var id = _a.id, orgName = _a.name, country = _a.country;
        return ({ id: id, name: country === null || country === void 0 ? void 0 : country.name, orgName: orgName });
    })
        .groupBy('orgName')
        .value();
});
var selectCompaniesByName = createSelector(selectCompanies, function (orgs) { return _.groupBy(orgs, 'name'); });
var selectCompanyNames = createSelector(selectCompaniesByName, function (orgs) { return _.map(orgs, function (_a) {
    var org = _a[0];
    return org;
}); });
var selectCompanyById = createSelector(selectCompanies, selectCompanyCountries, selectCompaniesByName, function (orgs, countries, orgsByName) {
    return _.chain(orgs)
        .map(function (org) {
        var _a;
        return (__assign(__assign({}, org), { subscriptionStartDate: moment(org.subscriptionStartDate), subscriptionEndDate: moment(org.subscriptionEndDate), countries: (_a = countries[org.name]) !== null && _a !== void 0 ? _a : [], orgs: orgsByName[org.name] }));
    })
        .keyBy('id')
        .value();
});
export var useUsersAPI = function () {
    return useAPI(USERS_API);
};
export var useUserAPI = function (id, orgType) {
    return useAPI(USER_API, { id: id, orgType: orgType });
};
export var useCompaniesAPI = function (orgType) {
    if (orgType === void 0) { orgType = OrgType.Cinareo; }
    var url = "".concat(ORG_API, "?orgType=").concat(orgType);
    return useAPI(url, { orgType: orgType });
};
export var useCompanyAPI = function (id) {
    var url = "".concat(ORG_API, "/").concat(id);
    return useAPI(url, { id: id });
};
export var useCountryAPI = function () {
    return useAPI(GET_COUNTRY_API);
};
export var useIndustryAPI = function () {
    return useAPI(GET_INDUSTRY_API);
};
export var useAddCompanyAPI = function () {
    return useAPI(ORG_API, {}, 'POST');
};
export var useUpdateCompanyAPI = function (id) {
    var url = "".concat(ORG_API, "/").concat(id);
    return useAPI(url, { id: id }, 'PUT');
};
export var useCreateUserAPI = function () {
    return useAPI(USERS_API, {}, 'POST');
};
export var useUpdateUserAPI = function () {
    return useAPI(USERS_API, {}, 'PUT');
};
export var useResetPasswordAPI = function (id) {
    return useAPI(RESET_PASSWORD_API, { id: id }, 'PUT');
};
export var useChangeStatusAPI = function () {
    return useAPI(CHANGE_STATUS_API, {}, 'PUT');
};
export var useCreateExportLogAPI = function () {
    return useAsyncDownload(CREATE_EXPORT_LOG_API);
};
export var useCreateBillingLogAPI = function () {
    return useAsyncDownload(CREATE_BILLING_LOG_API);
};
export var useSelectOrganizations = function (orgType) {
    var t = useTranslation('manageUser').t;
    var _a = useCompaniesAPI(orgType), _b = _a[0], data = _b.data, error = _b.error, requesting = _b.requesting, fetchCompanies = _a[1], clearCompanies = _a[2];
    var organizations = useMemo(function () { return __spreadArray([{ id: -1, name: t('billingLog.allCompanies') }], (data !== null && data !== void 0 ? data : []), true); }, [data, t]);
    return [{ data: organizations, error: error, requesting: requesting }, fetchCompanies, clearCompanies];
};
export var useCompanyNames = function (orgType) {
    var companiesData = useCompaniesAPI(orgType)[0];
    return selectCompanyNames(companiesData.data);
};
export var useCompanyById = function (orgType, id) {
    var companiesData = useCompaniesAPI(orgType)[0];
    return selectCompanyById(companiesData.data)[id !== null && id !== void 0 ? id : 0];
};
