var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuItem, Link as MuiLink } from '@mui/material';
import { gridNumberComparator, gridStringOrNumberComparator, } from '@mui/x-data-grid';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { EditScenarioDialog } from '../annual/editDialog';
import { useUserPermission } from '../app/selectors';
import { DataGrid, FallbackComponent, MenuButton, PAGE_SIZE } from '../common';
import { useDialog } from '../hooks';
import { DeletePlanDialog } from './deletePlan';
import { DuplicatePlanDialog } from './duplicatePlan';
import { usePlansAPI } from './selectors';
var DialogMode;
(function (DialogMode) {
    DialogMode[DialogMode["Delete"] = 0] = "Delete";
    DialogMode[DialogMode["Duplicate"] = 1] = "Duplicate";
    DialogMode[DialogMode["Edit"] = 2] = "Edit";
})(DialogMode || (DialogMode = {}));
export var RowMenu = function (props) {
    var t = useTranslation('myPlans').t;
    var permission = useUserPermission();
    return (_jsxs(MenuButton, { children: [_jsx(MenuItem, __assign({ component: Link, to: "/report/".concat(props.data.basePlanId), disabled: !props.data.status }, { children: t('viewReport') })), _jsx(MenuItem, __assign({ disabled: !(permission === null || permission === void 0 ? void 0 : permission.duplicatable), onClick: props.handleOpenDialog.bind(null, DialogMode.Duplicate, props.data) }, { children: t('duplicate') })), _jsx(MenuItem, __assign({ onClick: props.handleOpenDialog.bind(null, DialogMode.Delete, props.data) }, { children: t('delete') }))] }));
};
var useColumns = function (handleOpenDialog) {
    var t = useTranslation(['myPlans', 'translation']).t;
    var navigate = useNavigate();
    return useMemo(function () { return [
        {
            field: 'id',
            headerName: '',
            flex: 0.5,
            sortable: false,
            renderCell: function (params) { return _jsx(RowMenu, { data: params.row, handleOpenDialog: handleOpenDialog }); },
        },
        {
            field: 'name',
            headerName: t('nameOfPlan'),
            flex: 5,
            renderCell: function (params) { return (_jsx(MuiLink, __assign({ component: "button", onClick: params.row.hasMultiskill
                    ? handleOpenDialog.bind(null, DialogMode.Edit, params.row)
                    : function () { return navigate("/wizard/".concat(params.row.basePlanId)); }, title: params.value }, { children: params.value }))); },
        },
        {
            field: 'startDate',
            headerName: t('startDate'),
            flex: 2,
            valueFormatter: function (params) { return moment.utc(params.value).format(t('dateFormat.short', { ns: 'translation' })); },
        },
        {
            field: 'planType',
            headerName: t('planType'),
            flex: 1.5,
            valueGetter: function (params) { return params.value; },
        },
        {
            field: 'periodLength',
            headerName: t('length'),
            flex: 1.5,
            valueGetter: function (params) { return params.row; },
            valueFormatter: function (params) {
                return t("length".concat(params.value.planType), { length: params.value.periodLength });
            },
            sortComparator: function (v1, v2, param1, param2) {
                var planTypeComparatorResult = gridStringOrNumberComparator(v1.planType, v2.planType, param1, param2);
                if (planTypeComparatorResult) {
                    return planTypeComparatorResult;
                }
                return gridNumberComparator(v1.periodLength, v2.periodLength, param1, param2);
            },
        },
        {
            field: 'status',
            headerName: t('status'),
            flex: 1.5,
            valueGetter: function (_a) {
                var value = _a.value;
                return t('status', { context: "".concat(value) });
            },
            sortable: false,
        },
        {
            field: 'lastEdited',
            headerName: t('lastEdited'),
            flex: 2,
            type: 'dateTime',
            valueFormatter: function (params) { return moment(params.value).format(t('dateFormat.short', { ns: 'translation' })); },
        },
    ]; }, [handleOpenDialog, navigate, t]);
};
export var PlanTable = function () {
    var _a, _b, _c, _d, _e;
    var _f = useState({
        pageSize: PAGE_SIZE,
        page: 0,
    }), paginationModel = _f[0], setPaginationModel = _f[1];
    var _g = useState([]), sortModel = _g[0], setSortModel = _g[1];
    var _h = useDialog(), dialogMode = _h.dialogMode, selectedPlan = _h.data, handleOpenDialog = _h.handleOpenDialog, handleCloseDialog = _h.handleCloseDialog;
    var columns = useColumns(handleOpenDialog);
    var pageSize = paginationModel.pageSize, page = paginationModel.page;
    var _j = (_a = sortModel[0]) !== null && _a !== void 0 ? _a : {}, sortColumn = _j.field, sortOrder = _j.sort;
    var _k = usePlansAPI(), plansData = _k[0], fetchPlans = _k[1], clearPlansData = _k[2];
    useEffect(function () {
        return clearPlansData;
    }, [clearPlansData]);
    useEffect(function () {
        fetchPlans(undefined, {
            pageNumber: page + 1,
            pageSize: pageSize,
            sortColumn: sortColumn,
            sortOrder: sortOrder === null || sortOrder === void 0 ? void 0 : sortOrder.toUpperCase(),
        });
    }, [fetchPlans, sortColumn, page, pageSize, sortOrder]);
    return (_jsxs(FallbackComponent, { children: [_jsx(DataGrid, { columns: columns, rows: plansData.requesting ? [] : (_c = (_b = plansData.data) === null || _b === void 0 ? void 0 : _b.items) !== null && _c !== void 0 ? _c : [], paginationModel: paginationModel, paginationMode: "server", onPaginationModelChange: setPaginationModel, sortModel: sortModel, sortingMode: "server", onSortModelChange: setSortModel, sortingOrder: ['desc', 'asc'], autoHeight: true, rowCount: (_e = (_d = plansData.data) === null || _d === void 0 ? void 0 : _d.total) !== null && _e !== void 0 ? _e : 0, loading: plansData.requesting !== false }), _jsx(DeletePlanDialog, { open: dialogMode === DialogMode.Delete, plan: selectedPlan, onClose: handleCloseDialog }), _jsx(DuplicatePlanDialog, { open: dialogMode === DialogMode.Duplicate, plan: selectedPlan, onClose: handleCloseDialog }), _jsx(EditScenarioDialog, { scenario: selectedPlan, open: dialogMode === DialogMode.Edit, to: "/wizard/".concat(selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.basePlanId), onClose: handleCloseDialog })] }));
};
