import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled, useTheme } from '@mui/material';
import moment from 'moment/moment';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { PlanType } from '../../enum';
import { usePlatformContext } from '../app/selectors';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '../common';
import { useReportAPI } from '../report/selectors';
import { SUMMARY_TRAINING_KEY, SUMMARY_TRAINING_KEY_BOTTOM } from './constants';
import { useScenarioSelected, useTrainingSummaryTable } from './selectors';
import { TRAINING_STICKIES, TrainingTableHead } from './trainingTable';
var StyledTableContainer = styled(TableContainer)(function () { return ({
    maxHeight: '75vh',
    position: 'relative',
}); });
var SummaryTableBody = function (_a) {
    var _b;
    var id = _a.id, trainings = _a.trainings, bgColor = _a.bgColor, _c = _a.isSummary, isSummary = _c === void 0 ? false : _c;
    var t = useTranslation('report', { keyPrefix: 'training.summary' }).t;
    var rowKeys = isSummary ? SUMMARY_TRAINING_KEY_BOTTOM : SUMMARY_TRAINING_KEY;
    var theme = useTheme();
    var reportData = useReportAPI()[0];
    var isWeekly = ((_b = reportData.data) === null || _b === void 0 ? void 0 : _b.planType) === PlanType.Weekly;
    var getCellValue = function (inputKey, value) {
        if (inputKey === 'newHireClassStartDate' || inputKey === 'recruitingStartDate') {
            return value ? moment(value, 'DD-MMM-YY').format('DD-MM-YY') : value;
        }
        return value;
    };
    return (_jsx(TableBody, { children: rowKeys.map(function (key) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { stickySizes: TRAINING_STICKIES, color: theme.palette.background.default, component: isSummary ? 'th' : 'td', align: "left", value: t(key) }), trainings.map(function (training, index) {
                    var _a;
                    var isTotalColumn = !((index + 1) % 13);
                    return (_jsx(TableCell, { greyColor: isWeekly ? index === trainings.length - 1 : isTotalColumn && !isSummary, color: bgColor, bold: isSummary, highlightBorder: isTotalColumn && !isWeekly && trainings.length - 1 !== index, value: (_a = getCellValue(key, training[key])) !== null && _a !== void 0 ? _a : '' }, "".concat(index, ".").concat(id, ".").concat(key)));
                })] }, key + id)); }) }, id));
};
export var SummaryTable = function (_a) {
    var scenarioId = _a.scenarioId;
    var t = useTranslation('report', { keyPrefix: 'training.summary' }).t;
    var _b = useTrainingSummaryTable(scenarioId), queues = _b.queues, summary = _b.summary;
    var colors = useScenarioSelected(scenarioId).colors;
    var context = usePlatformContext();
    var theme = useTheme();
    return (_jsx(StyledTableContainer, { children: _jsxs(Table, { children: [queues.map(function (_a) {
                    var id = _a.id, name = _a.name, trainings = _a.trainings;
                    return (_jsxs(Fragment, { children: [_jsx(TrainingTableHead, { queueName: name, addColumns: ['Total'], bgColor: colors[0], disableWeekDate: true }), _jsx(SummaryTableBody, { id: id, trainings: trainings, bgColor: theme.palette.common.white })] }, id));
                }), _jsx(TrainingTableHead, { queueName: t('allQueues', { context: context }), addColumns: ['Total'], bgColor: colors[0], disableWeekDate: true }), _jsx(SummaryTableBody, { trainings: summary, isSummary: true, bgColor: colors[1] })] }) }));
};
